.addProjectDetails {
  padding: 25px;
  .createOption {
    justify-content: space-between;
    padding-bottom: 20px;
    .inputWrap {
      width: 190px;
      .label {
        padding-bottom: 9px;
        display: block; } } } }

.browseFileWrap {
  display: flex;
  align-items: center;
  .dzu-dropzone {
    flex: 1;
    background: #596886 !important;
    border: 1px solid #DCE0EA !important;
    box-sizing: border-box;
    border-radius: 3px;
    height: 65vh;
    margin-right: 20px;
    position: relative;
    margin-top: 20px;
    padding: 20px;
    .dzu-inputLabel {
      @include font(22px, 29px, #CDD8E9, $baseFont);
      font-weight: 300;
      background: #FAFCFF; }
    .whiteBtn {
      position: absolute;
      right: 20px;
      top: calc(50% - 20px); }
    .dzu-submitButtonContainer {
      margin: 0 0 -20px 0;
      width: calc(100% + 40px);
      min-height: 111px;
      height: 111px;
      background: #4E5E7C;
      display: flex;
      align-items: center;
      justify-content: center;
      .dzu-submitButton {
        background: $link !important;
        font-family: $baseFont !important; } }
    .dzu-previewContainer {
      padding: 20px 0;
      border-bottom: 1px solid #697897;
      .dzu-previewStatusContainer .dzu-previewButton {
        background: url(../images/crossWhite.svg) no-repeat 0 center !important;
        width: 18px;
        height: 18px; }
      .dzu-previewFileName {
        padding: 8px 0 7px 40px;
        color: $white;
        background: url(../images/file.svg) no-repeat 0 center;
        @include font(13px, 18px, $white, $baseFont); } } } }
