.projectListWrap {
  .circle {
    width: 165px;
    height: 174px;
    position: absolute;
    border-radius: 50%;
    left: -65px;
    top: -70px;
    opacity: 0.5; }
  & readyToDeliver {
    background: red; }
  .projectList {
    justify-content: space-between;
    .projectBlock {
      height: 100px;
      padding: 12px 0 17px 20px;
      color: $white;
      border-radius: 6px;
      overflow: hidden;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 1;
      .numbers {
        @include font(60px, 80px, $white);
        font-weight: bold;
        position: relative; }
      .projectStats {
        @include font(20px, 26px);
        text-transform: uppercase;
        margin-left: 19px;
        font-weight: bold; }

      &.readyToDeliver {
        opacity: 0.25;
        background: linear-gradient(250deg, #EE6054 0.81%, #FFA199 109.12%), #FFFFFF;
        box-shadow: 2px 15px 50px rgba(238, 96, 84, 0.25);
        .circle {
          background: #ED5548; } }
      &.onGoingProj {
        background: linear-gradient(278deg, #34C6C3 -2.44%, #72FCF9 103.25%),linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9));
        box-shadow: 2px 15px 50px 0px rgba(52, 198, 195, 0.25);
        margin: 0 27px;
        .circle {
          background: #3ADDDA; } }
      &.pendingProj {
        background: linear-gradient(278.48deg, #22A4EE 0.53%, #7ED0FF 99.9%), rgba(255, 255, 255, 0.9);
        box-shadow: 2px 15px 50px rgba(34, 164, 238, 0.25);
        .circle {
          background: #22A4EE; } }
      &.customersOnBoard {
        background: linear-gradient(90deg, #8CD06B -2.35%, #B0F68D 99.59%), rgba(255, 255, 255, 0.9);
        box-shadow: 2px 15px 50px rgba(120, 207, 77, 0.25); } } } }
.dashboardContent {
  margin-top: 50px;
  .block {
    flex: 1;
    .accordion {
      .accordionItem {
        margin-bottom: 2px;
        header {
          .customerName {
            @include font(14px, 18px, #515266); }
          .arrowWrap {
            align-items: center; }
          .deliverDate {
            @include font(12px, 16px, #717394);
            display: flex;
            align-items: center;
            .priorityStat {
              width: 12px;
              height: 12px;
              border-radius: 50%;
              cursor: pointer;
              &.red {
                background: #FF2D2D; }
              &.orange {
                background: #FFAB2D; }
              &.green {
                background: #39E373; } }
            .dateDeliver {
              padding: 0 19px 0 10px; } }
          .twoWayArrow {
            border-color: $link; } }
        section {
          ul li {
            @include font(13px, 17px, #717394);
            display: flex;
            justify-content: space-between;
            align-items: center;
            min-height: 52px;
            padding: 0 10px;
            &:not(:last-child) {
              border-bottom: 1px solid #F0F3F9; }
            .productTitle {
              display: flex;
              align-items: center;
              flex-basis: 15%;
              svg {
                fill: #515266; }
              span {
                padding-left: 12px;
                cursor: pointer;
                text-decoration: underline;
                color: $link;
                font-weight: 600; } } } } } } }

  .criticalAlert {
    width: 270px;
    margin-left: 30px;
    @include font(13px, 18px, #717394);
    svg {
      fill: #717394; }
    h2 {
      @include font(16px, 21px, #3E3E4F);
      font-weight: 600;
      margin-bottom: 22px; }
    .alertList {
      .alertItem {
        &:not(:last-child) {
          padding-bottom: 20px;
          border-bottom: 1px solid #DFE5F2; }
        &:not(:first-child) {
          padding-top: 25px; }
        .alertAvtar {
          width: 40px;
          height: 40px;
          border-radius: 50%;
          background: #DBE3F1;
          margin-right: 10px;
          display: flex;
          align-items: center;
          justify-content: center; }
        .alertContent {
          flex: 1;
          .alertFooter {
            display: flex;
            justify-content: space-between;
            margin-top: 12px;
            .alertDate {
              color: #BABBCA; }
            .viewMore {
              color: $link;
              text-decoration: underline;
              text-transform: capitalize; } } } } } } }
