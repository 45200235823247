body {
  @include font(13px, 16px, #000, $baseFont);
  height: 100%;
  background: #E5E5E5; }
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: none; }

div[id=root], html {
  height: 100%; }

a {
  cursor: pointer;
  text-decoration: none;
  color: $link;
  &:hover,
  &:focus, {
    text-decoration: none;
    color: $link;
    outline: none; } }
button[disabled],
html input[disabled] {
  cursor: default;
  color: #CC9999; }
li {
  list-style-type: none; }
.clearfix::after {
  content: "";
  clear: both;
  display: table; }

.form-control {
  height: 40px;
  @include font(16px, 18px, #aaa);
  border: 1px solid #eee;
  box-shadow: none; }

select::-ms-expand {
  display: none; }

.flex-column {
  flex-direction: column; }

.cursorPointer {
  cursor: pointer; }
.hide {
  display: none !important; }
.show {
  display: block; }

.relative {
  position: relative; }

.d-flex {
  display: flex; }
.flex-wrap {
  flex-wrap: wrap; }
.align-center {
  align-items: center; }
.justify-between {
  justify-content: space-between; }
.justify-center {
  justify-content: center !important; }
.center {
  text-align: center; }
.container {
  width: 100%; }

.twoWayArrow {
  width: 8px;
  height: 8px;
  border-right: 1px solid #000;
  border-bottom: 1px solid #000;
  transform: rotate(45deg); }

// SEARCH
.search {
  position: relative;
  input {
    width: 335px;
    height: 40px;
    line-height: 40px;
    border: 1px solid #D0D5DF;
    background: $white;
    border-radius: 6px;
    padding: 0 14px 0 17px;
    font-size: 13px;
    color: #515266;
    &::placeholder {
      @include font(13px, 17px, #BABBCA); }
    &:focus, .updated {
      border: 1px solid $link !important;
      color: $link; } }
  .searchBtn {
    background: none;
    border: 0;
    position: absolute;
    height: 40px;
    width: 40px;
    right: 0;
    border-radius: 0 6px 6px 0;
    cursor: pointer;
    svg {
      fill: $link;
      width: 16px;
      height: 16px; } } }

// TABLE
.tableWrap {
  background: #FFFFFF;
  box-shadow: 4px 15px 20px #E8EFFC;
  border-radius: 6px;
  padding: 20px;
  margin-top: 24px;
  header {
    align-items: center;
    justify-content: space-between; } }

.block {
  background: $white;
  box-shadow: 4px 15px 20px #E8EFFC;
  border-radius: 6px;
  padding: 15px 20px;
  h2 {
    @include font(18px, 24px,#41425E);
    text-transform: capitalize; } }
// BUTTON
.btn {
  border-radius: 6px;
  height: 40px;
  line-height: 40px;
  text-transform: uppercase;
  text-align: center;
  padding: 0 13px;
  font-size: 14px;
  font-weight: bold;
  border: 0;
  cursor: pointer;
  min-width: 120px;
  &:hover, &:focus {
    outline: 0; } }

.orangeBtn {
  background: $link;
  box-shadow: 4px 10px 15px rgba(238, 96, 84, 0.2);
  color: $white; }

.borderBtn {
  border: 1px solid $link;
  color: $link;
  background: $white; }

.btnBlue {
  min-width: 50px;
  height: 50px;
  background: #22A4EE;
  box-shadow: 10px 10px 40px rgba(0, 0, 0, 0.15);
  border-radius: 6px;
  border: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  @include font(14px, 19px, #fff);
  font-weight: 700; }

// INPUT
.inputBlock {
  display: flex;
  flex-direction: column;
  .label, label {
    margin-bottom: 9px;
    color: #717394;
    text-transform: capitalize;
    .requiredField {
      color: #FF0000; } } }

.inputWrap {
  position: relative;
  input,
  textarea {
    background: #FAFCFF;
    border: 1px solid #DCE0EA;
    border-radius: 4px;
    height: 40px;
    @include font(13px, 17px, #3e3e4f);
    width: 100%;
    padding: 0 10px;
    &:focus, &.updated {
      background: #FFFFFF;
      border: 2px solid $link;
      color: $link;
      font-weight: 600;
      &::placeholder {
        color: $link; } }
    &::placeholder {
      color: #CBD4E1; } }
  &.error {
    input,
    textarea {
      background: #FFF3F5 !important;
      border: 1px solid #E04F5F !important;
      color: #E04F5F; } }
  .inputbadge {
    position: absolute;
    z-index: 1;
    right: 10px;
    top: 10px;
    svg {
      fill: $link; } }
  textarea {
    resize: none;
    width: 100%;
    height: 80px;
    padding: 11px 10px; } }

.inputErrorMsg {
  @include font(11px, 15px, #E04F5F);
  text-transform: capitalize;
  margin-left: 15px; }

.selectWrap {
  position: relative;
  .twoWayArrow {
    display: inline-block;
    border-color: $link;
    position: absolute;
    top: 13px;
    right: 15px; }
  select {
    width: 100%; } }

select {
  border: 1px solid #DCE0EA;
  box-sizing: border-box;
  border-radius: 4px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: #FAFCFF;
  cursor: pointer;
  width: 190px;
  height: 40px;
  padding: 0 30px 0 10px;
  color: #B8BECC;
  font-weight: 400;
  &:focus {
    border-color: $link;
    color: $link;
    font-weight: 600;
    option {
      color: $black; } } }

.label {
  @include font(13px, 17px, #717394);
  margin-right: 11px;
  text-transform: capitalize;
  .requiredField {
    color: #FF0000; } }

// MOBILE FIELD
.mobileField {
  position: relative;
  select {
    position: absolute;
    height: 38px;
    width: 83px;
    border-radius: 4px 0 0 4px;
    padding: 0 0 0 12px;
    border: 0;
    left: 1px;
    top: 1px;
    border-right: 1px solid #ddd;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: url(../images/selectArrow.png) no-repeat 88% center;
    cursor: pointer; }
  input {
    padding-left: 93px !important; } }

.contentLeft {
  flex: 1; }

.contentRight {
  width: 270px;
  margin-left: 30px; }

.blockFooter {
  display: flex;
  align-items: center;
  justify-content: center; }

.pageRight {
  flex: 1;
  padding: 30px;
  background: #F1F5FD;
  margin-left: 300px;
  min-height: 100vh; }

// full Page Loader

.fullPageLoader {
  .overlay {
    background: rgba(0, 0, 0, 0.4);
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0; }
  .loaderInner {
    background: $white;
    border-radius: 4px;
    min-width: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 20px; } }

// Accordion
.accordion {
  .accordionItem {
    header {
      padding: 10px;
      display: flex;
      justify-content: space-between;
      background: #F1F5FD; } } }

.breadcrumb {
  @include font(13px, 15px, #717394, roboto);
  display: flex;
  li {
    text-transform: capitalize;
    &:not(:last-child) {
      background: url(../images/ArrowRight.svg) no-repeat right 0 center;
      padding-right: 25px;
      margin-right: 10px; } } }
.completeJobPage {
  table {
    tr td:first-child {
      color: #007ABE;
      font-weight: 600; } }
  .tableWrap {
    margin-top: 0; }
  .userSection {
    margin-bottom: 0;
    border-bottom: 0; } }

.Modal-module_modal__FqeFw.Modal-module_dialog__2UBIn {
  max-width: 720px;
  width: 720px; }
#stfDialogPlace {
  h3 {
    @include font(26px, 30px, #3E3E4F, roboto);
    font-weight: 700;
    padding: 30px;
    border-bottom: 1px solid #222756;
    text-transform: capitalize; }
  .stf__dialogClose {
    svg {
      width: 16.07px;
      height: 16.07px; } } }

.userSection {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  border-bottom: 1px solid #CFD2E8;
  padding-bottom: 28px;
  .welcomeUser {
    @include font(26px, 21px, #41425E, roboto);
    text-transform: capitalize;
    display: flex;
    justify-content: flex-start;
    flex: 1;
    align-items: center;
    padding-right: 70px;
    .UName {
      font-weight: 600;
      margin-left: 5px; } } }
.userInfoWrap {
  display: flex;
  align-items: center;
  position: relative;
  padding-bottom: 5px;
  .userAvatar {
    width: 41px;
    height: 41px;
    background: #ccc;
    border-radius: 50%;
    margin-left: 10px; }
  .userName {
    @include font(13px, 15px, #717394, roboto);
    display: flex;
    align-items: center;
    text-transform: capitalize;
    cursor: pointer;
    height: 41px;
    .twoWayArrow {
      border-color: $link;
      margin-left: 20px; } }
  .profileOptions {
    position: absolute;
    background: $white;
    box-shadow: 0px 25px 40px rgba(64, 75, 172, 0.2);
    width: 214px;
    top: 45px;
    z-index: 99;
    right: 0;
    display: none;
    li {
      padding: 16px 15px;
      @include font(12px, 14px, #14183B, roboto);
      display: flex;
      align-items: center;
      cursor: pointer;
      span {
        text-transform: capitalize; }
      &.profileItem,
      &.changePwdItem,
      &.signOutItem {
        img, svg {
          width: 20px;
          height: 20px;
          margin-right: 9px; } }
      &.signOutItem svg {
        fill: #14183B; }
      &:hover {
        color: #0B95ED;
        svg {
          fill: #0B95ED;
          path {
            fill: #0B95ED; } } } } }
  &:hover {
    .profileOptions {
      display: block; } } }

// My Profile :: Start
.myProfile-Content {
  background: #F0F2FF;
  box-shadow: 10px 10px 40px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  .myProfile-content-head {
    background: $white;
    height: 196px;
    border-radius: 6px;
    padding: 23px 20px;
    position: relative;
    .myProfile-title {
      @include font(18px, 24px, #41425E, $baseFont);
      font-weight: 600; }
    .myProfile-avatar {
      width: 150px;
      height: 150px;
      border: 2px solid #D2D5ED;
      border-radius: 50%;
      position: absolute;
      bottom: -33px;
      left: 0;
      right: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: auto;
      background: $white;
      .myProfile-avatarEdit {
        width: 34px;
        height: 34px;
        background: #22A4EE;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        box-shadow: 5px 5px 20px rgba(15, 49, 68, 0.25);
        position: absolute;
        right: 0;
        bottom: 18px;
        cursor: pointer; } } }
  .myProfile-form {
    padding: 64px 20px 70px;
    .myProfile-formRow {
      align-items: center;
      flex-wrap: wrap;
      .inputWrap {
        width: calc(33% - 12px);
        margin: 0 20px 20px 0;
        &:nth-child(3n+3) {
          margin-right: 0; }
        input {
          background: #E7E9FB;
          border: 1px solid #A1A5C1;
          border-radius: 100px;
          height: 49px;
          padding: 0 24px;
          color: #333333;
          &::placeholder {
            color: #333333; } } } } }
  .myProfile-action {
    display: flex;
    justify-content: center;
    padding-bottom: 91px;
    .profile-update-btn {
      background: #22A4EE;
      border-radius: 100px;
      width: 294.67px;
      height: 49px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 0;
      @include font(16px, 19px, $white, roboto);
      font-weight: 700;
      text-transform: uppercase;
      cursor: pointer; } } }
.detailsDrop {
  background: #E9EBFD;
  padding: 18px 20px 20px;
  margin: -10px -20px;
  @include font(13px, 17px, #222756);
  .firstrow {
    margin-bottom: 15px;
    .deatilsSection {
        margin-right: 85px; } }
  .deatilsLabel {
    font-weight: 600;
    margin-bottom: 5px; } }
.collapsed {
  .accordionArrowIcon {
    transform: rotate(180deg); } }
.expand {
  .accordionArrowIcon {
    transform: rotate(0deg); } }

.acceptBtn {
  .AcceptIcon {
    display: flex; }
  .AcceptFillIcon {
    display: none; } }
.rejectBtn {
  .RejectIcon {
    display: flex; }
  .RejectFillIcon {
    display: none; } }

.acceptBtn:hover {
  .AcceptIcon {
    display: none; }
  .AcceptFillIcon {
    display: flex; } }
.rejectBtn:hover {
  .RejectIcon {
    display: none; }
  .RejectFillIcon {
    display: flex; } }

.acceptBtn,
.rejectBtn {
  border: 0;
  background: none;
  padding: 0;
  border-radius: 50%;
  cursor: pointer;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    width: 25px;
    height: 25px; } }
.previewBtn {
  background: #fff;
  border: 1px solid #22A4EE;
  border-radius: 3px;
  padding: 0 10px;
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #22A4EE;
  font-weight: 600;
  text-transform: capitalize;
  cursor: pointer;
  &:hover {
    background: #22A4EE;
    color: #fff; } }
.acceptRejectText {
  @include font(13px, 17px);
  font-weight: 700;
  text-transform: capitalize;
  &.accept {
    color: #78CF4D; }
  &.reject {
    color: #FF4F4F; } }
.rhap_container {
  padding: 0 !important;
  width: calc(100% + 32px) !important;
  margin-left: -16px !important;
  margin-top: -8px !important;
  .rhap_main.rhap_stacked {
    .rhap_progress-section {
      background: #070D44;
      height: 64px;
      padding: 0 15px;
      .rhap_current-time, .rhap_total-time {
        color: #fff; }
      .rhap_progress-indicator {
        background: #fff !important; } }
    .rhap_controls-section {
      background: #222756;
      height: 102px;
      margin: 0;
      padding: 0 15px;
      .rhap_rewind-button,
      .rhap_forward-button {
        svg path {
          fill: #fff; } }
      .rhap_play-pause-button {
        width: 58.33px;
        height: 58.33px;
        svg {
          width: 58.33px;
          height: 58.33px;
          path {
            fill: #FF4F4F; } } } } } }
.Modal-module_modalButtonPlace__AYeOm {
  background: none !important;
  .Button-module_button__3FnXy {
    width: 200px;
    height: 50px;
    background: #22A4EE;
    @include font(14px, 19px #fff, $baseFont);
    font-weight: bold; } }
.popupHead {
  position: relative;
  h3 {
    padding: 30px 16px !important;
    margin-top: -8px;
    margin-bottom: 7px; }
  .popupClose {
    position: absolute;
    top: 8px;
    right: 3px;
    cursor: pointer;
    svg {
      width: 16.07px;
      height: 16.07px; } } }
.rta__autocomplete {
  background: #FFFFFF;
  box-shadow: 0px 4px 40px rgba(40, 43, 61, 0.1);
  width: 248px;
  height: 240px;
  position: absolute;
  overflow: hidden;
  overflow-y: auto;
  z-index: 99999;
  .rta__item {
    height: 29px;
    margin: 6px 0;
    padding: 5px 10px;
    color: #5F627A;
    &:hover {
      background: #22A4EE;
      color: #fff; } } }
.rta__entity--selected {
  color: #fff;
  text-decoration: none;
  background: #0366d6; }
.slide-pane {
  background: #E9EBFD;
  border-radius: 15px 0 0 0;
  .slide-pane__header,
  .slide-pane__content {
    background: #E9EBFD; }
  .slide-pane__header {
    height: 90px;
    border-radius: 15px 0 0 0;
    .slide-pane__title {
      @include font(26px, 30px, #3E3E4F, roboto);
      font-weight: bold; } }
  .slide-pane__content {
    padding: 0;
    .errortitle {
      background: #222756;
      padding: 15px 32px;
      @include font(16px, 19px, #fff, roboto);
      font-weight: 700;
      display: flex;
      align-items: center;
      svg {
        margin-right: 8px; } }
    .errorItem {
      @include font(13px, 17px, #D90000);
      font-weight: 600;
      padding: 15px 5px;
      display: flex;
      align-items: center;
      border-bottom: 1px solid #C5C8E9;
      margin: 0 20px;
      .twoWayArrow {
        margin: 0 10px 0 0;
        border-color: #D90000; } } } }
.slide-pane__overlay {
  z-index: 99999; }
.LogoWrap {
  width: 190px;
  height: 160px;
  margin: 0 auto;
  background: #E2E4F8;
  padding: 0 13px 14px;
  border-radius: 0 0 20px 20px;
  display: flex;
  align-items: flex-end;
  .logo {
    width: 163.29px;
    height: 50px; } }
.rejectFormWrap {
  display: flex;
  flex-direction: column;
  margin-bottom: 26px;
  label {
    @include font(14px, 19px, #222756);
    font-weight: 600;
    margin-bottom: 13px; }
  textarea {
    background: #F8F8FF;
    border: 1px solid #A1A5C1;
    border-radius: 10px;
    width: 100%;
    height: 200px; } }
.popupFooter {
  justify-content: flex-end; }
.spelling-error {
  background-color: #ffd54f; }
/*#zoomview-container
  .konvajs-content
    width: 100% !important
    canvas
      width: 100% !important*/
