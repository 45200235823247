.leftSideBar {
  width: 300px;
  background: linear-gradient(178.6deg, #111644 16.75%, #161A35 98.8%), #14172D;
  height: 100vh;
  position: fixed;
  .LogoWrap {
    height: 110px; }
  .navigationWrap {
    padding: 0 32px;
    margin-top: 44px; }
  .navItem {
    display: flex;
    align-items: center;
    padding: 0 0 0 22px;
    margin-bottom: 10px;
    height: 60px;
    cursor: pointer;
    svg {
      margin-right: 11px;
      fill: #fff;
      width: 20px;
      height: 20px; }
    .navLabel {
      @include font(13px, 17px, #fff); }

    &.active,
    &:hover {
      background: #222756;
      border-radius: 20px;
      color: #fff;
      svg {
        fill: #fff; }
      .navLabel {
        color: #fff;
        font-weight: normal; } } }
  .logoutLink {
    position: absolute;
    bottom: 20px;
    width: 100%; } }
