// .fullPagePopup
//     position: fixed
//     top: 0
//     left: 0
//     width: 100%
//     height: 100%
//     display: flex
//     align-items: center
//     justify-content: center
//     z-index: 99999
//     .overlay
//         background: $black
//         opacity: 0.8
//         left: 0
//         right: 0
//         top: 0
//         bottom: 0
//         position: fixed
.Modal-module_modal__FqeFw.Modal-module_dialog__2UBIn,
.Modal-module_modalTitle__1TtY9 {
    background: #E9EBFD; }
.popupInner {
    background: #E9EBFD;
    border-radius: 10px;
    position: relative;
    width: 720px;
    max-height: 94vh;
    .popupClose {
        position: absolute;
        top: 22px;
        right: 22px;
        cursor: pointer;
        svg {
            width: 16.07px;
            height: 16.07px; } }
    header {
        padding: 30px;
        border-bottom: 1px solid #222756; }
    .popupTitle {
        @include font(26px, 30px, #3E3E4F, roboto);
        font-weight: 700; }
    .popupContent {
        padding: 20px 30px 30px; } }
.twoWayArrow {
    transform: rotate(-45deg);
    margin-top: 7px;
    border-color: #222756; }
.label {
    margin-left: 9px;
    flex: 1;
    color: #222756;
    font-size: 13px;
    line-height: 20px; }
.shortcut-item {
    display: flex;
    align-items: center;
    @include font(13px, 20px, #222756);
    margin-bottom: 30px;
    .twoWayArrow {
        margin: 0; } }
.required {
    color: #D90000; }
.megdap-guidelines {
    max-height: calc(100vh - 138px);
    overflow: hidden;
    overflow-y: auto;
    .guidelines-list {
        .guidelines-item {
            border-bottom: 1px solid #C5C8E9;
            padding: 0 5px 20px;
            @include font(13px, 20px, #222756);
            margin-bottom: 20px;
            display: flex;
            p {
                margin-left: 9px;
                flex: 1; } } } }
.addSpeaker {
    padding: 0 !important;
    .speakerHeader {
        background: #222756;
        height: 50px;
        display: flex;
        align-items: center;
        color: #fff;
        padding: 0 30px;
        font-size: 15px;
        line-height: 20px;
        font-weight: 600;
        li {
            flex: 1;
            &:not(:last-child) {
                margin-right: 10px; }
            &.deleteCol {
                flex: 0 0 28px;
                margin-left: 10px; } } }
    .dilectWrap {
        padding: 20px 30px;
        align-items: center;
        label {
            @include font(14px, 19px, #222756);
            font-weight: bold;
            margin-right: 13px; }
        select {
            background: #F8F8FF;
            border: 1px solid #A1A5C1;
            border-radius: 6px;
            color: #222756;
            appearance: auto; } }

    .speakerContentWrap {
        padding: 23px 30px 0;
        max-height: calc(100vh - 286px);
        overflow: hidden;
        overflow-y: auto;
        .speaker-list {
            align-items: center;
            .speaker-item {
                flex: 1;
                margin-bottom: 10px;
                &:not(:last-child) {
                    margin-right: 10px; }
                &.deleteCol {
                    flex: 0 0 28px;
                    margin-left: 10px;
                    svg {
                        cursor: pointer; } }
                &.speakerFieldError {
                    input {
                        background: #FFF3F3;
                        border: 1px solid #D90000;
                        @include font(12px, 16px, #EB9595);
                        &::placeholder {
                            color: #EB9595; } } }
                select {
                    appearance: auto; }
                select,
                input {
                    width: 100%;
                    height: 49px;
                    background: #F8F8FF;
                    border: 1px solid #A1A5C1;
                    border-radius: 6px;
                    @include font(13px, 17px, #222756);
                    line-height: 49px;
                    padding: 0 13px; } } } }
    footer {
        display: flex;
        justify-content: space-between;
        padding: 22px 30px 27px;
        button {
            cursor: pointer; }
        .addSpeakerBtn {
            display: flex;
            align-items: center;
            background: none;
            border: 0;
            height: 21px;
            svg {
                width: 21px;
                height: 21px; }
            span {
                @include font(14px, 19px, #78CF4D, $baseFont);
                margin-left: 5.5px;
                text-transform: uppercase;
                font-weight: 700; } }
        .btnBlue {
            width: 200px;
            height: 50px; } } }
.Modal-module_modalBody__3_RoW {
    background: #E9EBFD; }
.Modal-module_overlay__21YAk {
    z-index: 999999; }
