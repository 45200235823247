.RegisterWrap {
  height: 110vh;
  background: #E2E4F8;
  .registerLHS {
    width: 450px;
    background: url(../images/loginLHSB-bg.jpg) no-repeat;
    height: 110vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-bottom: 38px;
    .logoSlogan-container {
      text-align: center;
      .poweredBy {
        @include font(12px, null, #888FCF);
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 26px;
        span {
          line-height: 16px; }
        .megdapLogo {
          width: 92.34px;
          height: 20px;
          margin-left: 12px;
          margin-top: 5px; } } }
    .description {
      width: 255px;
      @include font(11px, 15px, #fff);
      margin: 0 auto;
      a {
        color: #fff; } } }
  .registerRHS {
    width: calc(100% - 450px);
    display: flex;
    align-items: center;
    justify-content: center;
    background: #E2E4F8; } }

.registrationForm {
  width: 510px;
  .welcometxt {
    @include font(18px, 23.94px, #41425E);
    margin-bottom: 43px;
    span {
      font-weight: 700; } }
  .registerFormWrap {
    .firstLastField {
      justify-content: space-between;
      .inputWrap {
        flex-basis: 48%; } }

    .inputWrap.error {
      .css-2b097c-container {
        input {
          border: 0 !important;
          background: none !important; } } }
    .css-2b097c-container {
      .css-yk16xz-control,
      .css-1pahdxg-control {
        background: #E7E9FB;
        border: 1px solid #A1A5C1;
        border-radius: 100px;
        height: 49px;
        width: 100%;
        padding: 0 0 0 24px; }

      .css-1wa3eu0-placeholder {
        @include font(14px, null, #333); }

      .css-1okebmr-indicatorSeparator {
        display: none; }
      .css-b8ldur-Input {
        input {
          height: auto; } } }

    .inputWrap {
      margin-bottom: 20px;
      input {
        background: #E7E9FB;
        border: 1px solid #A1A5C1;
        border-radius: 100px;
        height: 49px;
        width: 100%;
        padding: 0 0 0 24px;
        &::placeholder {
          @include font(14px, null, #333); }
        &.error {
          input::placeholder {
            color: #E04F5F; } } }
      .inputErrorMsg {
        margin-left: 25px; } }
    .formFooter {
      button {
        @include font(16px, 19px, #fff, roboto);
        background: $link;
        border-radius: 100px;
        height: 49px;
        text-transform: uppercase;
        width: 100%;
        border: 0;
        font-weight: 700;
        cursor: pointer; }
      .loginLink {
        text-align: center;
        margin-top: 20px;
        @include font(13px, 17.29px, #999);
        a {
          color: $link;
          text-decoration: underline;
          font-weight: 600; } } } }
  .mobileField {
    position: relative;
    select {
      border-radius: 100px 0 0 100px;
      padding: 0 0 0 20px;
      border: 0;
      border-right: 1px solid #ddd;
      top: 0;
      bottom: 0;
      margin: auto; }
    input {
      padding-left: 100px !important; } } }
.passwordField {
  position: relative;
  .passwordEye {
    position: absolute;
    top: 15px;
    right: 22px;
    cursor: pointer;
    svg {
      width: 18px;
      height: 18px; }
    &.active {
      svg > path {
        fill: $link; } } } }
.loginTitle {
  @include font(22px, 29px, #41425E);
  margin-bottom: 38px;
  strong {
    font-weight: bold; } }
.loginForm {

  .orDivider {
    @include font(14px, 19px, #bbb);
    padding: 0 13px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 24px;
    &::before,
    &::after {
      content: '';
      position: absolute;
      background: #eee;
      height: 1px;
      width: 45.2%; }
    &::after {
      right: 0; }
    &::before {
      left: 0; } }
  .remember-forgotPass {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 15px 0 38px;
    .rememberMeWrap {
      display: flex;
      align-items: center;
      cursor: pointer;
      input {
        width: 15px;
        height: 15px;
        border: 1px solid #ccc;
        margin-right: 10px; }
      label {
        @include font(12px, 16px, #999);
        font-weight: 600;
        margin-top: -1px; } }
    .Forgotpassword {
      @include font(13px, 17px, $link);
      text-decoration: underline;
      cursor: pointer; }
    .IsmanagerAccess {
       @include font(13px, 17px); } } }

.partTimeWrap {
  padding: 30px 0;
  border-top: 1px solid #B9BCCF;
  display: flex;
  align-items: center;
  color: #41425E;
  input[type="checkbox"] {
    width: 16px;
    height: 16px;
    background: #E7E9FB;
    border: 1px solid #A1A5C1;
    border-radius: 4px; }
  label {
    @include font(12px, 16px);
    margin: 0 13px 0 7px;
    border-right: 1px solid #A1A5C1;
    padding-right: 10px; }
  .hrsInput {
    width: 65px;
    height: 27px;
    background: #FFFFFF;
    border: 1px solid #22A4EE;
    border-radius: 4px;
    padding: 0 8px;
    margin-right: 9px; }
  .noBorder {
    border: 0; } }
